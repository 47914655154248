import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavImage,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  // NavBtn,
  // NavBtnLink,
} from "./NavbarElements";
import { FaBars } from "react-icons/fa";
import PiccLogo from "../../images/picc_logos2.png";

export const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <NavImage src={PiccLogo} />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="about">About</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="staff">Staff</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="services">Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="contact">Contact</NavLinks>
            </NavItem>
          </NavMenu>
          {/* <NavBtn>
            <NavBtnLink to="signin">Sign In</NavBtnLink>
          </NavBtn> */}
        </NavbarContainer>
      </Nav>
    </>
  );
};
