import React from "react";
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink } from "./FooterElements";
export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Mobile PICCs</FooterLinkTitle>
              <FooterLink to="/">Phone: (909) 768-4222</FooterLink>
              <FooterLink to="/">Fax: (951) 418-3032</FooterLink>
              <FooterLink to="/">MobilePICCs@gmail.com</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};
