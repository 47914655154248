import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  // ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
// import Icon1 from "../../images/services.svg";
// import Icon2 from "../../images/services.svg";
// import Icon3 from "../../images/services.svg";

export const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          {/* <ServicesIcon src={Icon1} /> */}
          <ServicesH2>
            Peripherally Inserted Central Catheters (PICC Lines)
          </ServicesH2>
          <ServicesP>
            PICC lines are inserted into the upper arm and threaded into the big
            veins just above the heart. These lines are used for long-term
            antibiotics ({">"} 4 weeks), chemotherapy, parenteral nutrition, and
            in some cases, lifesaving medication. People requiring PICCs need to
            sign a consent form and meet certain criteria. These will be emailed
            to you upon scheduling. We offer competitive pricing and will strive
            to provide a wonderful experience.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* <ServicesIcon src={Icon2} /> */}
          <ServicesH2>Midlines</ServicesH2>
          <ServicesP>
            Like PICC’s, Midlines are inserted into the upper arm but are only
            threaded into the veins just below the axilla. These lines are used
            for long-term antibiotic ({"<"} 4 weeks), hydration, and stable
            venous access. People requiring Midlines need to meet certain
            criteria which will be emailed to you upon scheduling. No consent is
            needed for Midline placement.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* <ServicesIcon src={Icon3} /> */}
          <ServicesH2>
            Ultrasound Guided Peripheral Venous Access (IVs)
          </ServicesH2>
          <ServicesP>
            Mobile PICCs uses Ultrasound to place all peripheral venous
            catheters. This ensures that the IV is successful in as few attempts
            as possible. These lines are placed in the forearm and are used for
            short-term access for people requiring infusions, or for patients
            staying in facilities. If needed, patients can schedule an IV start
            and infusion together!
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* <ServicesIcon src={Icon1} /> */}
          <ServicesH2>Infusion</ServicesH2>
          <ServicesP>
            Mobile PICCs offers infusion therapy! This can be extremely
            convenient to medical groups, long term care facilities and
            individuals alike. Save time by scheduling with us and we will bring
            the infusion to you!
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          {/* <ServicesIcon src={Icon1} /> */}
          <ServicesH2>Troubleshooting/Education</ServicesH2>
          <ServicesP>
            For patients who have PICCs or Midlines that are not working,
            contact us and we will come out to fix the problem. We offer
            dressing changes, tPA administration, power-flushing and exchanges.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};
