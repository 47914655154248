import React from "react";
import {
  HeroContainer,
  HeroBg,
  HeroImage,
  HeroContent,
  HeroH1,
  HeroP,
} from "./HeroElements";
import Medicine from "../../images/medicine.svg";

export const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <HeroBg ><HeroImage src={Medicine}/></HeroBg>
      <HeroContent>
        <HeroH1>Mobile PICCs</HeroH1>
        <HeroP>Mobile PICC Line and Infusion Therapy Services</HeroP>
      </HeroContent>
    </HeroContainer>
  );
};
