export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About",
  headline: "Mobile PICCs",
  description:
    "Mobile PICCs is a 24hr/7 day a week mobile PICC company servicing San Bernardino, Los Angeles and San Diego Counties.  Services provided include Adult and Pediatric peripheral venous access, specialty catheter placement, infusion therapy, de-clotting and education.  We offer contracts to hospitals and long-term care facilities but are also available to private practice groups, venues and individuals.  Contact us to schedule an appointment today!!",
  buttonLabel: "Get Connected",
  imgStart: false,
  img: require("../../images/medical_care.svg").default,
  alt: "nurses",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "staff",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Staff",
  headline: "No Job is Too Big or Small! ",
  description:
    "Mobile PICCs employees are hand-picked, highly trained and incredibly efficient.   Our goal is to get out to you as quickly as possible; to save you time and money.  All Mobile PICC’s staff members are proficient in caring for adult and pediatric populations.  Give us a call today to see what we can do for you!",
  buttonLabel: "Contact Us",
  imgStart: true,
  img: require("../../images/services.svg").default,
  alt: "nurses",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact Us",
  headline: "In the Redlands area",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  buttonLabel: "Get started",
  imgStart: true,
  img: require("../../images/contact.svg").default,
  alt: "nurses",
  dark: false,
  primary: false,
  darkText: true,
};
