import emailjs from "emailjs-com";
import React, { useRef } from "react";
import {
  Card,
  CardContent,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
// import { Button } from "../ButtonElements";
import { Center } from "../Center";

export function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ucwln3o",
        "template_c9no4dj",
        form.current,
        "UVzAL_nn8KVNxwdrj"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <Center>
      <Card
        sx={{
          width: 400,
        }}
      >
        <CardContent id="contact" sx={{ textAlign: "center" }}>
          <Typography variant="h3" sx={{ my: 3 }}>
            Contact Us
          </Typography>
          <Box
            sx={{
              "& .MuiTextField-root": {
                margin: 1,
                width: "90%",
              },
            }}
          >
            <form ref={form} onSubmit={sendEmail}>
              <TextField
                required
                label="Name"
                name="name"
                variant="outlined"
                type="text"
              />
              <TextField
                required
                label="Email"
                name="email"
                variant="outlined"
                type="email"
              />
              <TextField
                required
                label="Subject"
                name="subject"
                variant="outlined"
              />
              <TextField
                required
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={8}
              />
              {/* <input type="submit" className="btn btn-info" value="Send Message"></input> */}
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="error"
              >
                Submit
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Center>
  );
}
